<template>
  <ContentWrapper>
    <template v-slot:title>Sign Out</template>
    <Container>
      <h1>Review & Ratings</h1>
      <StarRating :show-rating="false" v-model="ratings"></StarRating>
      <div class="textarea-wrapper">
         <TextArea 
          v-model="review"
          id="message"
          placeholder="Please rate us before providing your feedback"
          rows="8"
          :disabled="!fieldToggle"
          :maxLength="300"
        />
        <p class="reviewLength">{{reviewLength}}</p>
      </div>
      <SubmitButton @onButtonClick="logout">Sign Out</SubmitButton>
    </Container>
  </ContentWrapper>
</template>

<script>
import StarRating from 'vue-star-rating'
import TextArea from '@/components/TextArea'
import SubmitButton from '@/components/SubmitButton'

//API
import { logout } from '@/services/api/users.js';
import { ratingStore } from "@/services/api/others.js";

export default {
  components:{
    StarRating,
    TextArea,
    SubmitButton
  },
  data(){
    return{
      ratings: 0,
      review: null,
      reviewLength: '(0/300)',
      fieldToggle: false,
    }
  },
  methods:{
    async logout(){
      try {
        if(this.ratings && this.review) {
          await ratingStore({rating: this.ratings, review: this.review});
        }else if(!this.ratings && this.review){
          this.$swal({
            icon: 'warning',
            title: 'Oops!',
            text: 'Please rate us before providing your feedback'
          })
          return;
        }
          
        await logout();
        await this.$store.commit('updateAuthenticated',{
          authenticated : false,
          userProfile : null, 
          accessToken: null
        });

      } catch (error) {
        console.log(error);
      }
    }
  },
  watch:{
    ratings(data){
      data > 0 ? this.fieldToggle = true : this.fieldToggle = false
    },
    review(data){
      this.reviewLength = `(${data.length}/300)`
    }

  }
}
</script>

<style lang="scss" scoped>
  p{
    color: var(--gray414241);
  }

  .reviewLength{
    margin-top: 5px;
    text-align: right;
    font-size: 12px;
  }

  .content-container{
   
  }

  .content-container{
    width: 60%;
    text-align: center;
    padding: 30px 100px;
    margin: 0 auto;
    min-height: calc(100vh - 160px);

    @media screen and (max-width:900px){
      width: 90%;
    }

    @media screen and (max-width:768px) {
      min-height: calc(100vh - 90px);
    }

    @media screen and (max-width:600px) {
      min-height: initial;
      height: calc(100vh - 60px - 58px);
    }

    @media screen and (max-width:450px){
      width: 100%;
      margin: 0;
      padding: 30px 20px;
    }
  }

  .vue-star-rating{
    justify-content: center;
    margin: 1.5rem ;
  }
  
  .submit-button{
    margin: 0 auto;
    margin-top: 5rem;

    @include respond(phone){
      margin: 3rem auto;
    }

  }
</style>